import { createIconifyIcon } from '../create-icon.ts';
import './load';

const SvgStarOutlineIcon = createIconifyIcon('svg:star-outline');

const SvgReturnIcon = createIconifyIcon('svg:return');

const SvgShoppingCartIcon = createIconifyIcon('svg:shopping-cart');

export { SvgStarOutlineIcon, SvgReturnIcon, SvgShoppingCartIcon };

