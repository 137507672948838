import dayjs from 'dayjs';

function dateRange(unit: dayjs.OpUnitType): [number, number] {
  return [
    dayjs().startOf(unit).valueOf(),
    dayjs().endOf(unit).valueOf()
  ];
}

export { dayjs, dateRange };
